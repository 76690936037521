.footer {
    background-color: #f8f9fa; /* Light background */
    padding: 20px;
    position: relative;
    bottom: 0;
    width: 100%;
    display: flex; /* Use flexbox for layout */
    justify-content: space-between; /* Space between left and right */
    align-items: center; /* Center items vertically */
  }
  
  .footer-content {
    width: 100%;
    display: flex; /* Flex for left and right alignment */
    justify-content: space-between; /* Align items to left and right */
    align-items: center;
  }
  
  .footer-left {
    display: flex;
    flex-direction: column; /* Stack logo and name vertically */
    align-items: flex-start; /* Align items to the left */
  }
  
  .footer-logo {
    height: 40px; /* Adjust logo size */
  }
  
  .footer-name {
    font-size: 14px; /* Adjust font size */
    color: #333; /* Dark text color */
    margin-top: 5px; /* Space between logo and name */
  }
  
  .footer-right {
    display: flex; /* Align social icons horizontally */
    gap: 15px; /* Space between icons */
  }
  
  .social-icon {
    font-size: 20px; /* Icon size */
    color: #333; /* Icon color */
    transition: color 0.3s ease; /* Transition for hover effect */
  }
  
  .social-icon:hover {
    color: #007bff; /* Change color on hover */
  }
  