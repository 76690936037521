/* Resources.css */

.resources-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: #f9f9f9;
  }
  
  .left-side,
  .right-side {
    flex: 1;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .resource-image {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .resource-description {
    font-size: 1.2em;
    color: #333;
    line-height: 1.6;
  }
  
  .highlight {
    color: #336699; /* Light dark blue color */
    font-weight: bold;
  }
  
  .divider {
    width: 100%;
    margin: 20px 0;
    border: none;
    border-top: 1px solid #ccc;
  }
  
  @media (min-width: 768px) {
    .resources-container {
      flex-direction: row;
    }
  
    .left-side,
    .right-side {
      width: 50%;
    }
  
    .left-side {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    .right-side {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  
  @media (max-width: 767px) {
    .resources-container {
      flex-direction: column;
    }
  
    .left-side,
    .right-side {
      width: 100%;
      padding: 10px;
    }
  
    .resource-description {
      text-align: center;
    }
  }
  