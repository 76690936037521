.about-us-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .about-us-heading {
    text-align: center;
    font-size: 2em;
    margin-bottom: 20px;
  }
  
  .about-us-content {
    font-size: 1em;
    line-height: 1.6;
  }
  
  .about-us-content h2 {
    font-size: 1.5em;
    margin-top: 20px;
    font-weight: bold;
  }
  
  .about-us-content ul {
    list-style-type: disc;
    margin-left: 20px;
    margin-top: 10px;
  }
  
  .about-us-content ul li {
    margin-bottom: 10px;
  }
  
  .about-us-content ul li strong {
    font-weight: bold;
  }
  