.blog-container {
  margin-top: 20px; /* Space from header */
}

.categories {
  display: flex;
  flex-wrap: wrap;
  background-color: #f5f5f5; /* Grey background */
  padding: 10px; /* Padding around categories */
}

.category-item {
  margin-right: 20px; /* Space between items */
  cursor: pointer;
  padding: 5px 10px; /* Padding for clickable area */
  transition: color 0.3s;
}

.category-item.active {
  color: #007bff; /* Color for the active category */
  font-weight: bold; /* Bold text for the active category */
}

.blog-list {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.blog-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  border: 1px solid #d3d3d3;
  padding: 20px;
  background-color: #fff;
}

.featured-image {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.blog-content {
  text-align: left;
}

.blog-content h2 {
  margin: 0 0 10px;
}

.blog-content p {
  margin: 0 0 20px;
}

.blog-content a {
  color: #007bff;
  text-decoration: none;
}

.blog-content a:hover {
  text-decoration: underline;
}
