.App {
  background-color: #fdfdfd; /* Set background color */
  color: #030303; /* Set text color */
  min-height: 100vh; /* Ensure at least full viewport height */
  display: flex;
  flex-direction: column;
  overflow-x: hidden; /* Prevent horizontal overflow */
}

.content {
  flex: 1; /* Take up remaining vertical space */
  padding-top: 60px; /* Adjust according to header height */
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-shadow: 0 2px 4px rgba(96, 92, 92, 0.1);
  background-color: #ffffff; /* White background */
  color: #000000; /* Black text color */
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1000;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); /* Light black line */
}

.logo img {
  height: 40px;
  margin: 10px;
}

.nav {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex: 1;
}

.nav a {
  text-decoration: none;
  color: #000000; /* Change text color to black */
  font-size: 14px;
  padding: 10px 15px;
  transition: color 0.3s ease;
}

.nav a:hover {
  color: #007bff;
}

.menu-icon {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.menu-icon .bar {
  width: 25px;
  height: 3px;
  background-color: #060606;
  margin: 4px 0;
  transition: all 0.3s ease;
}

.login-signup {
  margin-right: 20px;
}

.login-signup-button {
  font-size: 14px;
  padding: 10px 20px;
  background-color: #ffffff; /* White background */
  color: #000000; /* Black text color */
  border: 2px solid #000000; /* Black border */
  border-radius: 5px;
  cursor: pointer;
  outline: none; /* Remove default focus outline */
}

.login-signup-button:hover {
  background-color: #f0f0f0; /* Light gray background on hover */
}

.login-signup-button:focus {
  border-color: #000000; /* Black border on focus */
}

/* Flex container for layout */
.flex-container {
  display: flex; /* Use flexbox for layout */
  align-items: center; /* Center items vertically */
  padding: 20px; /* Add some padding */
}

.text-content {
  flex: 1; /* Take up available space on the left */
  margin-right: 20px; /* Space between text and image */
}

.image-container {
  flex: 1; /* Take up available space on the right */
  display: flex;
  justify-content: center; /* Center image horizontally */
}

.home-image {
  max-width: 100%; /* Ensure the image does not exceed its container width */
  height: auto; /* Maintain aspect ratio */
  max-height: 300px; /* Set maximum height for the image */
}

.description-text {
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 20px;
}

.heading {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 20px;
}

.short-share {
  color: darkblue; /* Dark blue color for Short and Share */
}

.earn-highlight {
  background: linear-gradient(to right, darkblue, lightblue);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; /* Make the text color transparent to show gradient */
}

/* Responsive styles */
@media (max-width: 768px) {
  .flex-container {
    flex-direction: column; /* Stack items vertically */
    align-items: flex-start; /* Align items to the start */
  }

  .text-content {
    margin-right: 0; /* Remove right margin */
    margin-bottom: 20px; /* Add space below text */
  }

  .image-container {
    width: 100%; /* Full width for image container */
    justify-content: center; /* Center image */
  }

  .nav {
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: #f9f6f6;
    display: none; /* Hide navigation by default */
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .nav.open {
    display: flex; /* Show navigation when open */
  }
  .nav.open {
  display: flex; /* Show when open */
  flex-direction: column; /* Stack items vertically */
  background-color: #ffffff; /* White background */
  position: absolute; /* Overlay on the page */
  top: 60px; /* Below the header */
  left: 0; /* Align to the left edge */
  width: auto; /* Fit the width to the content */
  max-width: none; /* Remove max-width to allow full width */
  z-index: 999; /* Ensure it is above other elements */
  padding: 10px; /* Add some padding */
  border: 1px solid #d3d3d3; /* Optional: add a border */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: add shadow for depth */
}

  .menu-icon {
    display: flex; /* Show menu icon on small screens */
  }

  .login-signup {
    display: flex; /* Ensure this is visible */
    margin-right: 10px; /* Adjust margin for mobile */
  }
}
.contact-button {
  display: inline-block;
  margin-top: 20px; /* Space above the button */
  padding: 10px 20px;
  background-color: #007bff; /* Button color */
  color: white; /* Text color */
  border: none;
  border-radius: 5px;
  text-decoration: none; /* Remove underline */
  font-size: 16px; /* Font size */
  transition: background-color 0.3s ease; /* Smooth transition */
}

.contact-button:hover {
  background-color: #0056b3; /* Darker shade on hover */
}
.divider {
  border: none; /* Remove default border */
  height: 1px; /* Height of the line */
  background-color: #d3d3d3; /* Light grey color */
  margin: 20px 0; /* Margin for spacing above and below */
}
.subheading {
  text-align: center; /* Center the text */
  font-size: 20px; /* Adjust font size */
  font-weight: bold; /* Make the text bold */
  margin: 20px 20px; /* Add some margin */
  color: #000; /* Optional: Set text color */
}
.companies-marquee {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  position: relative;
  background-color: #f5f5f5;
  padding: 20px 0;
}

.companies-container {
  display: flex;
  animation: scroll 20s linear infinite;
}

.company-item {
  display: inline-flex;
  align-items: center;
  margin: 0 15px;
}

.company-icon {
  margin-right: 5px;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

@media (max-width: 768px) {
  .flex-container {
    flex-direction: column;
  }

  .companies-container {
    animation: scroll 40s linear infinite;
  }

  .description-text, .subheading {
    font-size: 18px;
  }

  .heading {
    font-size: 24px;
  }

  .contact-button {
    font-size: 14px;
  }
}
.join-affichain {
  
  text-align: center;
  font-size: 20px;
  margin: 20px 0;
  background: linear-gradient(180deg, rgb(74, 74, 147), rgb(27, 43, 185));
  background-size: 100% 200%; /* Makes the gradient twice the height */
  background-position: 0% 100%; /* Start from the bottom */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: flow 3s linear infinite;
  font-weight: bold; /* Adjusted duration for faster flow */
}

@keyframes flow {
  0% {
    background-position: 0% 100%; /* Start position */
  }
  100% {
    background-position: 0% 0%; /* End position */
  }
}
.bold-line {
  width: 100%; /* Set to 100% of the text width */
  height: 3px; /* Thickness of the line */
  background-color: black; /* Color of the line */
  margin-top: 5px; /* Space between text and line */
  position: absolute; /* Position relative to text */
  bottom: -5px; /* Adjusts line position */
  left: 0; /* Aligns to the left */
}
.cards-container {
  display: flex; /* Use flexbox for layout */
  justify-content: space-between; /* Space between cards */
  flex-wrap: wrap; /* Allow cards to wrap on smaller screens */
  margin: 20px 10px; /* Margin above and below, and 10px on the sides */
}

.card {
  background-color: #ffffff; /* Card background */
  border: 1px solid #d3d3d3; /* Border for card */
  border-radius: 5px; /* Rounded corners */
  padding: 20px; /* Padding inside the card */
  margin: 10px; /* Margin around each card */
  flex: 1; /* Flex-grow to fill space equally */
  min-width: 250px; /* Minimum width for each card */
  max-width: calc(100% - 20px); /* Ensure it fits within the container */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.card h3 {
  margin-top: 0; /* Remove default margin from heading */
}

@media (max-width: 768px) {
  .cards-container {
    flex-direction: column; /* Stack cards vertically */
    align-items: center; /* Center cards horizontally */
    padding: 0 10px; /* Add padding on the sides */
  }

  .card {
    width: 100%; /* Full width for cards on small screens */
    margin: 10px 0; /* Margin above and below each card */
  }
}
.card h3 {
  margin-top: 0; /* Remove default margin from heading */
  font-weight: bold; /* Make the heading bold */
}
#voice-icon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  background-color: #f00;  /* Customize */
  border-radius: 50%;
  cursor: pointer;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;  /* Customize */
  font-size: 24px;  /* Customize */
}
.call-icon-overlay {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #007bff;
  color: white;
  font-size: 24px;
  padding: 15px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1000;
  transition: background-color 0.3s ease;
}

.call-icon-overlay:hover {
  background-color: #0056b3;
}

.call-icon-overlay.blinking {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
