.main-content {
  padding: 20px;
}

.flex-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.text-content {
  flex: 1;
  padding: 20px;
  max-width: 600px;
}

.heading {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 20px;
}

.affichain-highlight {
  color: #ff4500; /* Customize this color as needed */
}

.description-text {
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 20px;
}

.contact-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #ff4500; /* Customize this color as needed */
  color: #ffffff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.contact-button:hover {
  background-color: #e63900; /* Customize this color as needed */
}

.image-container {
  flex: 1;
  max-width: 600px;
  display: flex;
  justify-content: center;
  padding: 20px;
}

.solutions-image {
  width: 100%;
  height: auto;
  max-width: 400px;
}

/* Responsive styles */
@media (max-width: 768px) {
  .flex-container {
    flex-direction: column;
    align-items: center;
  }

  .text-content, .image-container {
    max-width: 100%;
    padding: 10px;
  }

  .text-content {
    order: 2; /* Show text content below image on small screens */
  }

  .image-container {
    order: 1; /* Show image above text content on small screens */
  }

  .solutions-image {
    max-width: 100%;
  }
}
.divider {
  border: none; /* Remove default border */
  height: 1px; /* Height of the line */
  background-color: #d3d3d3; /* Light grey color */
  margin: 20px 0; /* Margin for spacing above and below */
}
.cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Responsive grid */
  gap: 20px; /* Space between cards */
  padding: 20px; /* Padding around the card container */
}

.card {
  background-color: #ffffff; /* Card background color */
  border: 1px solid #d3d3d3; /* Card border */
  border-radius: 8px; /* Rounded corners */
  padding: 15px; /* Inner padding */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Shadow for depth */
  text-align: center; /* Center align text */
  transition: transform 0.3s ease; /* Smooth transition for hover effect */
}

.card:hover {
  transform: scale(1.05); /* Enlarge card on hover */
}

.card h3 {
  font-size: 20px; /* Service name font size */
  margin-bottom: 10px; /* Space below service name */
}

.card-image {
  width: 100%; /* Make image responsive */
  height: 150px; /* Fixed height for uniformity */
  object-fit: cover; /* Ensure images cover the area without distortion */
  max-width: 100%; /* Ensure it doesn't exceed the card */
  border-radius: 4px; /* Rounded corners for the image */
}

.card-description {
  font-size: 14px; /* Description font size */
  margin-top: 10px; /* Space above description */
}
@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInUp {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideInDown {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.animate-from-left {
  animation: slideInLeft 0.5s forwards;
}

.animate-from-right {
  animation: slideInRight 0.5s forwards;
}

.animate-from-up {
  animation: slideInUp 0.5s forwards;
}

.animate-from-down {
  animation: slideInDown 0.5s forwards;
}

.card {
  transition: transform 0.3s ease;
}

.card:hover {
  transform: scale(1.05);
}

.card-image {
  width: 100%;
  height: auto; /* Ensures all images have the same size */
}
/* Divider styles */
.divider {
  border: none;
  height: 1px;
  background-color: #d3d3d3;
  margin: 20px 0;
}

/* Styles for Why Choose Us */
.why-choose-us {
  text-align: center;
  font-size: 24px;
  margin: 20px 0;
}

/* Thank You Divider */
/* Thank You Divider */
.thank-you-divider {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.thank-you-text {
  padding: 0 10px;
  font-weight: bold;
  color: #ff4500; /* Customize as needed */
}

.thank-you-divider::before,
.thank-you-divider::after {
  content: "";
  flex: 1;
  height: 1px;
  background-color: #d3d3d3;
}

.thank-you-text {
  padding: 0 10px;
  font-weight: bold;
  color: #ff4500; /* Customize as needed */
}
