@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply m-0 font-sans antialiased;
}

code {
  @apply font-mono;
}
