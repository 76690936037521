.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0; /* Adjusted padding for top and bottom only */
  background-color: #ffffff; /* White background */
  color: #000000; /* Black text */
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1000;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.logo img {
  height: 40px;
  margin: 10px;
}

.site-name {
  font-family: 'YourCreativeFont', sans-serif; /* Use your desired font */
  font-size: 24px; /* Adjust size as needed */
  margin-left: 20px; /* Space between logo and site name */
}

.nav {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.nav a {
  text-decoration: none;
  color: #000000; /* Change text color to black */
  font-size: 14px;
  padding: 10px 15px;
  transition: color 0.3s ease;
}
.nav a:hover {
  transform: scale(1.1); /* Slightly enlarge on hover */
}

.login-signup {
  margin-right: 20px;
  z-index: 1001; /* Ensure the button is above other content */
}

.login-signup-button {
  font-size: 14px;
  padding: 10px 20px;
  background-color: #ffffff; /* White background */
  color: #000000; /* Black text */
  border: 2px solid #000000; /* Black border */
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease; /* Added for hover effect */
}

.login-signup-button:hover {
  background-color: #f0f0f0; /* Light gray on hover */
}

.menu-icon {
  display: none; /* Hidden by default */
  flex-direction: column;
  cursor: pointer;
}

.menu-icon .bar {
  width: 25px;
  height: 3px;
  background-color: #000000; /* Black bars */
  margin: 4px 0;
  transition: all 0.3s ease;
}

@media (max-width: 768px) {
  .header {
    justify-content: space-between;
  }

  .nav {
    display: none; /* Hide navigation by default */
    flex-direction: column;
    position: absolute;
    top: 60px; /* Adjust based on header height */
    left: 0;
    width: 100%;
    background-color: #ffffff; /* White background */
    z-index: 999; /* Ensure it overlays other content */
  }

.nav.open {
  display: flex; /* Show when open */
  flex-direction: column; /* Stack items vertically */
  background-color: #ffffff; /* White background */
  position: absolute; /* Overlay on the page */
  top: 60px; /* Below the header */
  left: 0; /* Align to the left edge */
  width: auto; /* Fit the width to the content */
  max-width: none; /* Remove max-width to allow full width */
  z-index: 999; /* Ensure it is above other elements */
  padding: 10px; /* Add some padding */
  border: 1px solid #d3d3d3; /* Optional: add a border */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: add shadow for depth */
}
  .menu-icon {
    display: flex; /* Show menu icon on small screens */
  }

  .login-signup {
    /* Ensure this stays visible on small screens */
    margin-right: 10px; /* Adjust margin for mobile */
  }
}
.blog-categories {
  position: relative;
}

.category-dropdown {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Align items to the start */
  padding: 10px 15px;
  background-color: #ffffff;
  border: 1px solid #d3d3d3; /* Optional: border for dropdown */
}

.category-options {
  position: absolute;
  top: 100%; /* Position below the dropdown */
  left: 0;
  background-color: #ffffff;
  border: 1px solid #d3d3d3;
  max-height: 200px; /* Max height for scrolling */
  overflow-y: auto; /* Enable scrolling */
  z-index: 999; /* Ensure it overlays other elements */
}

.category-options ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.category-options li {
  padding: 10px 15px;
  text-align: left; /* Align text to the left */
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflow text */
  text-overflow: ellipsis; /* Add ellipsis if text overflows */
}


.category-options li:hover {
  background-color: #f0f0f0; /* Highlight on hover */
}

.arrow {
  margin-left: 5px;
  transition: transform 0.3s ease;
}

.arrow.open {
  transform: rotate(180deg); /* Rotate arrow when open */
}
