.contact-page {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background-color: #f4f4f4; /* Light background */
  flex-wrap: wrap; /* Allow wrapping for smaller screens */
}

.contact-banner {
  flex: 1;
  margin-right: 20px; /* Space between description and form */
  display: flex;
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  text-align: center; /* Center text */
}

.contact-banner-text {
  font-size: 18px;
  color: #333;
}

.contact-form {
  flex: 1;
  background: white;
  border: 1px solid #ccc; /* Grey border */
  border-radius: 5px; /* Rounded edges */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Shadow effect */
  padding: 20px;
}

.contact-form h2 {
  font-weight: bold; /* Make heading bold */
  text-align: center; /* Center align heading */
  margin-bottom: 20px; /* Add margin below heading */
}

.contact-form label {
  display: block; /* Stack labels and inputs */
  margin-bottom: 10px; /* Space between label and input */
}

.contact-form input,
.contact-form textarea {
  width: 100%; /* Full width */
  padding: 10px;
  margin: 10px 0; /* Space between inputs */
  border: 1px solid #ccc; /* Grey border */
  border-radius: 4px; /* Rounded edges */
}

.contact-form button {
  background-color: #007bff; /* Button color */
  color: white; /* Text color */
  border: none;
  border-radius: 4px; /* Rounded edges */
  padding: 10px 15px;
  cursor: pointer;
}

.contact-form button:hover {
  background-color: #0056b3; /* Darker button on hover */
}

.submission-message {
  margin-top: 10px; /* Space above the message */
  color: green; /* Success message color */
  font-weight: bold; /* Make it stand out */
}

@media (max-width: 768px) {
  .contact-page {
    flex-direction: column; /* Stack vertically on small screens */
  }
  .contact-banner {
    margin-right: 0; /* Remove margin for small screens */
    margin-bottom: 20px; /* Space between description and form */
  }
  .contact-form {
    width: 100%; /* Ensure form takes full width */
  }
}
