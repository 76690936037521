.privacy-policy-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .privacy-policy-heading {
    text-align: center;
    font-size: 2em;
    margin-bottom: 10px;
  }
  
  .privacy-policy-updated {
    text-align: center;
    font-size: 1em;
    color: #888;
    margin-bottom: 20px;
  }
  
  .divider {
    border: none;
    height: 1px;
    background-color: #ddd;
    margin: 20px 0;
  }
  
  .privacy-policy-content {
    font-size: 1em;
    line-height: 1.6;
  }
  
  .privacy-policy-content h2 {
    font-size: 1.5em;
    margin-top: 20px;
    font-weight: bold;
  }
  
  .privacy-policy-content h3 {
    font-size: 1.2em;
    margin-top: 15px;
    font-weight: bold;
  }
  
  .privacy-policy-content ul {
    list-style-type: disc;
    margin-left: 20px;
  }
  
  .thank-you {
    text-align: center;
    font-size: 1em;
    margin-top: 20px;
  }
  
  .thank-you hr.divider {
    margin: 10px 0;
  }
  